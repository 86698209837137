import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Header, Label, Segment } from 'semantic-ui-react';

import withErrors from '../../../helpers/withErrors';

import * as api from '../cardActions';

import './CardHome.css';

const EmptyView = () => (
  <div className='card-home mt-5 pt-3'>
    <div className='col-md-8 offset-md-2 text-center'>
      <span
        style={{ fontSize: '80px', fontWeight: 'bold' }}
        role='img'
        aria-label='jsx-a11y'
      >
        😅
      </span>
      <h3 style={{ marginBottom: '40px' }}>
        Oops, that card does not seem to exist.
      </h3>
      <Link to='/' className='btn btn-primary'>
        Go Home
      </Link>
    </div>
  </div>
);

class CardHome extends Component {
  state = {
    card: {},
    showFront: false,
    showModalType: undefined,
  };

  componentWillMount() {
    const { cardId } = this.props.match.params;

    if (cardId) {
      this.fetchCard(this.props.match.params);
    }
  }

  onClick = () => {
    const showFront =
      this.props.history.location.hash === '#front' ||
      this.props.history.location.hash === ''
        ? false
        : true;
    this.setState({ showFront });
    const nextState =
      this.props.history.location.hash === '#front' ? '#back' : '#front';
    this.props.history.push(this.props.history.location.pathname + nextState);
  };

  onGoto = (event, data) => this.props.history.push(data.value);

  fetchCard = (params) => {
    api.fetchPublicCard(params).then((response) => {
      this.setState(() => ({ card: response.data }));
    });
  };

  render() {
    const { card, showFront } = this.state;
    const { deck } = card;

    if (!card || Object.keys(card).length === 0) {
      return <EmptyView />;
    }

    const cardContent =
      this.props.history.location.hash === '#front' ||
      this.props.history.location.hash === ''
        ? card.front
        : card.back;

    return (
      <div className='card-home mt-4'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-10 offset-md-1 col-lg-8 offset-lg-2'>
              <div className='card-home-header d-flex'>
                <Header as='h3' className='text-uppercase m-0'>
                  Card
                </Header>
              </div>
              <hr className='my-2' />
              <Segment
                className='card-home-panel mb-2'
                padded
                onClick={this.onClick}
              >
                <Label
                  attached='bottom'
                  onClick={this.onGoto}
                  value={`/decks/${deck.topic || 'general'}/${deck.title}-${
                    deck._id
                  }`}
                  as='a'
                >
                  {deck.title}
                </Label>
                <Label attached='bottom right'>
                  Login to see {showFront ? 'front' : 'back'}
                </Label>
                <h3 className='text-center my-5'>{cardContent}</h3>
              </Segment>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CardHome.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withErrors(CardHome);
