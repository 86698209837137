import React, { Component } from "react";
import {
  Button,
  Segment,
  Form,
  Header,
  Input,
  Divider,
  Message,
} from "semantic-ui-react";

import * as api from "./userActions";

class Billing extends Component {
  state = { error: false };
  updatePassword = (event) => {
    api.createPortalSession().then(({ data }) => (window.location = data.url));
  };

  render() {
    return (
      <Segment padded>
        <Form
          onSubmit={this.updatePassword}
          // error={this.state.error}
          // success={this.state.success}
        >
          <Header>
            <Header.Content>Billing</Header.Content>
            <Header.Subheader>
              Manage your subscription settings.
            </Header.Subheader>
          </Header>
          <Divider />
          <Message error header='Invalid Password' content={this.state.error} />
          <Message success header='Password Updated' />
          <Header>Subscriptions</Header>
          <Form.Field>
            <Input type='hidden' name='lookup_key' value='Basic' />
          </Form.Field>
          <Form.Field className='mt-4'>
            <Button type='submit' basic primary>
              Manage Plan
            </Button>
          </Form.Field>
        </Form>
      </Segment>
    );
  }
}

export default Billing;
