import axios from '../../helpers/authAxios';

export const fetchCards = (deckId) => {
  return axios.get(`/api/cards?deck=${deckId}`);
};

export const fetchPublicCards = (deckId) => {
  return axios.get(`/api/cards/public?deck=${deckId}`);
};

export const fetchCard = (cardId) => {
  return axios.get(`/api/cards/${cardId}`);
};

export const fetchPublicCard = (params) => {
  //TODO: inlcude remainding params and add checks
  return axios.get(`/api/cards/public/${params.cardId}`);
};

export const editCard = (card) => {
  return axios.put(`/api/cards/${card._id}`, card);
};

export const createCard = (params) => {
  return axios.post('/api/cards', params);
};

export const deleteCard = (cardId) => {
  return axios.delete(`/api/cards/${cardId}`);
};

export const resetCard = (cardId) => {
  return axios.delete(`/api/cards/${cardId}/review`);
};
