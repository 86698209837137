import React, { Component } from "react";
import cx from "classnames";
import { Button, Form, Message } from "semantic-ui-react";

import isAuthenticated from "../../helpers/isAuthenticated";

import * as api from "./authActions";
import FieldError from "./FieldError";

const errors = {
  400: "These credentials do not match our records.",
  500: "Something happened to your request. Please try again or contact us.",
};

class ForgotPassword extends Component {
  state = {
    email: "",
    errors: {
      email: undefined,
      form: undefined,
    },
    isSuccess: false,
  };

  componentWillMount() {
    if (isAuthenticated()) {
      this.props.history.push("/");
    }
  }

  onBlur = (event) =>
    this.validateFields(event.target.name, event.target.value);

  onChange = (event) => {
    const { name, value } = event.target;
    this.setState(() => ({ [name]: value }));
  };

  onSubmit = (event) => {
    event.preventDefault();
    const { email } = this.state;

    this.setState(
      {
        errors: {
          ...this.state.errors,
          email: this.validateEmail(email),
        },
      },
      () => this.forgotPassword(email)
    );
  };

  forgotPassword = (email) => {
    const { errors } = this.state;
    if (!errors.email) {
      api.forgotPassword({ email }).then(
        (response) => {
          this.setState({
            isSuccess: true,
            errors: { ...this.state.errors, form: "" },
          });
        },
        (error) => this.handleError(error)
      );
    }
  };

  resetPassword = (token, newPassword, verifyPassword) => {
    const { errors } = this.state;
    if (!errors.newPassword && !errors.verifyPassword) {
      api.resetPassword({ token, newPassword, verifyPassword }).then(
        (response) => {
          this.setState({
            isSuccess: true,
            errors: { ...this.state.errors, form: "" },
          });
          this.props.history.push("/");
        },
        (error) => this.handleError(error)
      );
    }
  };

  handleError = (error) => {
    const { response = {} } = error;
    const message = errors[response.status];
    this.setState({ errors: { ...this.state.errors, form: message } });
  };

  validateEmail = (email) => {
    const isValid = String(email)
      .toLowerCase()
      .match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      );

    return !isValid ? "Please enter a valid email." : undefined;
  };

  validateFields = (fieldName, value) => {
    switch (fieldName) {
      case "email":
        this.setState(({ errors }) => ({
          errors: { ...errors, email: this.validateEmail(value) },
        }));
        break;
      default:
        break;
    }
  };

  render() {
    const { errors, isSuccess } = this.state;
    return (
      <div className='reset-page'>
        <div className='container mt-5'>
          <div className='row'>
            <div className='col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3'>
              <h1 className='h4 mb-3 text-center'>Forgot Password</h1>
              <Form error={!!errors.form} success={isSuccess}>
                <Message
                  success
                  content='Check your email! Password reset instructions has been sent.'
                />
                <Message error content={errors.form} />
                <Form.Field>
                  <label>Email</label>
                  <input
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                    name='email'
                    type='email'
                    autoComplete='email'
                    placeholder='Enter your email'
                    className={cx({ "border-danger": errors.email })}
                    pattern="/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/"
                    required
                  />
                  {errors.email && <FieldError label={errors.email} />}
                </Form.Field>
                <Button onClick={this.onSubmit} type='submit' primary fluid>
                  Submit
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
