// @flow
import React, { Component } from 'react';
import { Header, Button, Input } from 'semantic-ui-react';
import type { Deck } from '../../types';

import withErrors from '../../helpers/withErrors';

import * as api from './deckActions';

import DeckCard from './DeckCard';

import './Decks.css';

const EmptyView = ({
  title,
  description,
  emoji = '✌️',
}: {
  title: string,
  description: string,
  emoji?: string,
}) => (
  <div className='text-center ml-auto mr-auto my-5'>
    <div className='text-center'>
      <div className='row'>
        <div className='col-md-6 offset-md-3'>
          <Header size='large'>
            {emoji} {title}
            <Header.Subheader
              className='text-secondary'
              style={{ lineHeight: '1.4em' }}
            >
              {description}
            </Header.Subheader>
          </Header>
        </div>
      </div>
    </div>
  </div>
);

type Props = {
  history: any,
  onError: any,
};

type State = {
  decks: Array<Deck>,
  filter: string,
};

class Decks extends Component<Props, State> {
  state = {
    decks: [],
    maxDecks: 3,
    filter: '',
    isLoading: true,
    isAdmin: false,
  };

  componentWillMount = () => {
    this.fetchDecks();
  };

  onGoto = (event: Event, data: any) => this.props.history.push(data.value);

  onSearch = ({ target }: { target: HTMLInputElement }) =>
    this.setState({ filter: target.value });

  fetchDecks = () => {
    api.fetchDecks().then((response) => {
      this.setState({
        decks: response.data.decks,
        maxDecks: response.data.maxDecks,
        isLoading: false,
        isAdmin: response.data.isAdmin,
      });
    });
  };

  render() {
    const { decks = [], maxDecks, filter, isLoading, isAdmin } = this.state;

    const filteredDecks =
      filter.length > 0
        ? decks.filter((deck) => deck.title.indexOf(filter) !== -1)
        : decks;

    return (
      <div className='decks-page mt-4'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='decks-container-header'>
                <div>
                  <h1 className='h5 m-0'>Decks</h1>
                  {isAdmin ? (
                    <p className='text-secondary m-0'>
                      {decks.length} decks in your collection
                    </p>
                  ) : (
                    <p className='text-secondary m-0'>
                      {decks.length} of {maxDecks} decks used in your collection
                    </p>
                  )}
                </div>
                <div className='decks-container-actions'>
                  {decks.length > 0 && (
                    <Input
                      className='mr-3'
                      icon='search'
                      onChange={this.onSearch}
                      placeholder='Search for decks...'
                    />
                  )}
                  <Button
                    onClick={this.onGoto}
                    value='decks/new'
                    primary
                    className='bg-blue-800 hover:bg-blue-700'
                  >
                    Create Deck +
                  </Button>
                </div>
              </div>
              <hr className='mt-2 mb-2' />
              {!isLoading && filteredDecks.length > 0 ? (
                <div className='row'>
                  {filteredDecks.map((deck, key) => (
                    <DeckCard
                      className='col-12 col-sm-12 col-md-6 col-lg-4 float-left'
                      deck={deck}
                      key={key}
                    />
                  ))}
                </div>
              ) : (
                <EmptyView
                  title={
                    isLoading
                      ? 'Loading decks...'
                      : 'No decks in your collection yet'
                  }
                  description={
                    isLoading
                      ? 'Satellites are beeping, electrons are whirling, and your data is on its way to your device...'
                      : "Decks are groups of related cards for organizing your notes. Haven't created a deck yet? No problem. Click 'Create Deck +' to get started."
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withErrors(Decks);
