import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { NotFound, ComingSoon } from "../pages";
import {
  Signup,
  Login,
  Logout,
  ReqAuth,
  ResetPassword,
  ForgotPassword,
  AppSumoSignup,
  StripeSuccess,
} from "./auth";
import { Decks, DeckHome, PublicDeckHome, DeckNew } from "./decks";
import Settings from "./settings/Settings";
import CardHome from "./cards/home/CardHome";
import CardPublicHome from "./cards/home/CardPublicHome";
import Review from "./review/Review";
import ReviewNew from "./review/new/ReviewNew";

import { NavBar, Footer } from "../components";
import isAuthenticated from "../helpers/isAuthenticated";
import GoogleAnalytics from "../helpers/GoogleAnalytics";

import "./App.css";

class App extends Component {
  render() {
    return (
      <BrowserRouter forceRefresh={true}>
        <div className='App'>
          <NavBar className='App-navbar' />
          <div className='App-content py-5'>
            <Route path='/' component={GoogleAnalytics} />
            <Switch>
              <Route exact path='/' component={Login} />
              <Route path='/login' component={Login} />
              <Route path='/logout' component={Logout} />
              <Route path='/signup' component={Signup} />
              <Route path='/appsumo' component={AppSumoSignup} />
              <Route path='/reset-password' component={ResetPassword} />
              <Route path='/forgot-password' component={ForgotPassword} />

              {/* Stripe pages */}
              <Route path='/success' component={StripeSuccess} />

              <Route path='/settings' component={ReqAuth(Settings)} />
              <Route exact path='/decks' component={ReqAuth(Decks)} />
              <Route exact path='/decks/new' component={ReqAuth(DeckNew)} />
              <Route
                exact
                path='/decks/:topic/:title-:deckId'
                component={
                  isAuthenticated() ? ReqAuth(DeckHome) : PublicDeckHome
                }
              />
              <Route
                exact
                path='/decks/:title-:deckId'
                component={
                  isAuthenticated() ? ReqAuth(DeckHome) : PublicDeckHome
                }
              />
              <Route
                exact
                path='/decks/:topic/:deckId/:title'
                component={
                  isAuthenticated() ? ReqAuth(DeckHome) : PublicDeckHome
                }
              />
              <Route
                exact
                path='/decks/:deckId/:title'
                component={
                  isAuthenticated() ? ReqAuth(DeckHome) : PublicDeckHome
                }
              />
              <Route
                exact
                path='/decks/:topic/:deckId/:title/flashcard/:cardId/:question'
                component={
                  isAuthenticated() ? ReqAuth(CardHome) : CardPublicHome
                }
              />
              <Route
                exact
                path='/decks/:deckId/:title/flashcard/:cardId/:question'
                component={
                  isAuthenticated() ? ReqAuth(CardHome) : CardPublicHome
                }
              />
              <Route
                exact
                path='/decks/:topic/:deckId/:title/flashcard/:cardId/:answer'
                component={
                  isAuthenticated() ? ReqAuth(CardHome) : CardPublicHome
                }
              />
              <Route
                exact
                path='/decks/:deckId/:title/flashcard/:cardId/:answer'
                component={
                  isAuthenticated() ? ReqAuth(CardHome) : CardPublicHome
                }
              />
              <Route
                exact
                path='/cards/:cardId'
                component={
                  isAuthenticated() ? ReqAuth(CardHome) : CardPublicHome
                }
              />
              <Route
                exact
                path='/sessions/new'
                component={ReqAuth(ReviewNew)}
              />
              <Route
                exact
                path='/sessions/:sessionId'
                component={ReqAuth(Review)}
              />

              <Route path='/about' component={ComingSoon} />
              <Route path='/api' component={ComingSoon} />
              <Route path='/help' component={ComingSoon} />

              <Route exact path='*' component={NotFound} />
            </Switch>
          </div>
          <Footer className='App-footer' />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
