import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  Input,
  Segment,
  Dropdown,
  Checkbox,
  Header,
} from 'semantic-ui-react';
import slugify from 'slugify';

import withErrors from '../../../helpers/withErrors';

import * as api from '../deckActions';
import * as tagApi from '../tagActions';

class DeckNew extends Component {
  state = {
    title: '',
    description: '',
    options: [],
    selectedTags: [],
    topic: '',
    publicDeck: true,
  };

  componentWillMount() {
    this.fetchTags();
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onSubmit = (e) => this.createDeck();

  onCancel = () => this.props.history.push('/decks');

  onAddTag = (e, { value }) => {
    this.createTag(value).then((tag) => {
      const option = { text: tag.value, value: tag._id };
      this.setState({
        options: [option, ...this.state.options],
        selectedTags: [...this.state.selectedTags, tag._id],
      });
    });
  };

  onChangeTag = (e, data) => {
    const { value } = data;
    const { options } = this.state;
    const tags = value.filter((el) => options.find((tag) => tag.value === el));
    this.setState({ selectedTags: tags });
  };

  fetchTags = () => {
    tagApi.getTags().then(({ data }) => {
      const options = data.map((tag) => ({ text: tag.value, value: tag._id }));
      this.setState({ options });
    });
  };

  onClickPublicCheckbox = () =>
    this.setState({ publicDeck: !this.state.publicDeck });

  createDeck = () => {
    const { title, description, selectedTags, topic, publicDeck } = this.state;

    api
      .createDeck({ title, description, tags: selectedTags, topic, publicDeck })
      .then((response) => {
        this.props.history.push(
          `/decks${
            response.data.topic ? `/${slugify(response.data.topic, '_')}` : ''
          }${
            response.data.title ? `/${slugify(response.data.title, '_')}` : ''
          }-${response.data._id}`
        );
      })
      .catch((error) => {
        const isLimitError =
          error.response.data.message.includes('Reached allowed deck limit') !==
          -1;
        if (isLimitError) {
          this.props.onError(
            'Delete a deck to add more, or upgrade to create more decks. <a target="_blank" href="https://www.munkle.it/pricing">Click here for Pricing</a>'
          );
        } else {
          this.props.onError(error.response.data.message);
        }
      });

    this.setState((state) => ({
      title: '',
      description: '',
      selectedTags: [],
      topic: '',
      publicDeck: true,
    }));
  };

  createTag = (value) => {
    return tagApi.createTag(value).then(({ data }) => data);
  };

  render() {
    const { title, description, options, selectedTags, topic, publicDeck } =
      this.state;

    return (
      <div className='container mt-4'>
        <div className='row'>
          <div className='col-sm-8 offset-sm-2 col-md-8 offset-md-2 col-lg-6 offset-lg-3'>
            <Segment>
              <h1 className='h4 mb-3 text-center font-weight-bold'>
                Create a new deck
              </h1>
              <Form>
                <Form.Field required>
                  <label>Deck title</label>
                  <Input
                    value={title}
                    onChange={this.onChange}
                    name='title'
                    placeholder='Add a deck title...'
                  />
                </Form.Field>
                <Form.Field>
                  <label>Deck Topic</label>
                  <Input
                    value={topic}
                    onChange={this.onChange}
                    name='topic'
                    placeholder='Add a deck topic...'
                  />
                </Form.Field>
                <Form.Field>
                  <label>Deck description</label>
                  <Input
                    value={description}
                    onChange={this.onChange}
                    name='description'
                    placeholder='Add a short description for this deck...'
                  />
                </Form.Field>
                <Form.Field>
                  <label>Tags</label>
                  <Dropdown
                    value={selectedTags}
                    placeholder='Add tags...'
                    options={options}
                    fluid
                    multiple
                    search
                    selection
                    allowAdditions
                    onAddItem={this.onAddTag}
                    onChange={this.onChangeTag}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Deck Visibility</label>
                  <Checkbox
                    onChange={this.onClickPublicCheckbox}
                    checked={publicDeck}
                    name='publicDeck'
                    id='publicDeck'
                    label={
                      <label htmlFor='publicDeck'>
                        <Header size='small'>Make a deck public</Header>
                      </label>
                    }
                  />
                </Form.Field>
                <Form.Field className='mt-4'>
                  <Button onClick={this.onSubmit} primary fluid>
                    Create Deck
                  </Button>
                </Form.Field>
              </Form>
            </Segment>
          </div>
        </div>
      </div>
    );
  }
}

DeckNew.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withErrors(DeckNew);
