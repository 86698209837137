import React, { Component } from 'react';
import cx from 'classnames';
import isAuthenticated from '../helpers/isAuthenticated';

class Footer extends Component {
  render() {
    const { className } = this.props;
    const authenticated = isAuthenticated();

    return (
      <div
        className={cx(className, 'footer container text-secondary mt-5 mb-4')}
      >
        <div className='row'>
          <div className='col-lg-10 offset-lg-1 d-flex justify-content-between'>
            <div className='left-side'>
              <small className='d-inline m-2'>
                <span>Munkle.it &copy; 2023</span>
              </small>
              <small className='d-inline m-2'>
                <a className='ml-2' href='mailto:hello@munkle.it'>
                  Contact
                </a>
              </small>
            </div>
            <div className='right-side'>
              <small className='d-inline m-2'>
                <a
                  href='https://www.munkle.it'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Newsletter
                </a>
              </small>
              {authenticated && process.env.REACT_APP_FEEDBACK_FORM_URL && (
                <small className='d-inline m-2'>
                  <a
                    href={process.env.REACT_APP_FEEDBACK_FORM_URL}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Feedback Form
                  </a>
                </small>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
