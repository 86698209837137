import React, { Component } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { Header, Label, Tab } from 'semantic-ui-react';

import * as api from '../deckActions';
import * as cardApi from '../../cards/cardActions';
import withErrors from '../../../helpers/withErrors';
import { NotFound } from '../../../pages';
import { OverviewTab } from './tabs';

import './DeckHome.css';

const errors = {
  400: 'Unable to fulfill request. Please try a valid url or go back.',
  403: 'Unable to fulfill request. You might not have access to this deck.',
  500: 'Something happened to your request. Please try again or contact us.',
};

const Subheader = ({ description }) =>
  description ? (
    <p className='text-secondary h5 font-weight-light mb-2'>{description}</p>
  ) : (
    <p className='text-secondary h5 font-italic font-weight-light mb-2'>
      No description provided
    </p>
  );

class PublicDeckHome extends Component {
  state = {
    deck: {},
    cards: [],
    maxCards: 100,
    showModalType: undefined,
    selectedCard: undefined,
    isLoading: true,
    isAdmin: false,
  };

  componentWillMount() {
    const { deckId } = this.props.match.params;

    if (deckId) {
      this.fetchDeck(deckId);
      this.fetchCards(deckId);
    }
  }

  fetchDeck = (deckId) => {
    api.fetchPublicDeck(deckId).then(
      (response) => {
        this.setState({
          deck: response.data.deck,
          isAdmin: response.data.isAdmin,
          isLoading: false,
        });
      },
      (error) => this.handleError(error)
    );
  };

  fetchCards = (deckId) => {
    cardApi.fetchPublicCards(deckId).then(
      (response) => {
        this.setState({ cards: response.data, isLoading: false });
      },
      (error) => this.handleError(error)
    );
  };

  handleError = (error) => {
    const { response = {} } = error;
    const errorMessage =
      errors[response.status] ||
      'Unable to fulfill request. Please try a valid url or go back.';
    this.setState({ isError: true });
    this.props.onError(errorMessage);
  };

  render() {
    const { deck, cards, isLoading, isError } = this.state;

    if (isError) {
      return <NotFound />;
    }

    return (
      <div className='deck-home mt-4'>
        <div className='container'>
          <div className='row'>
            <div className='position-relative col-lg-10 offset-lg-1'>
              <Header as='h6' className='text-secondary text-uppercase m-0'>
                DECK
              </Header>
              <div className='deck-header'>
                <h1
                  className='font-weight-bold h3 mb-0 mt-0'
                  style={{ fontSize: '22px', lineHeight: '1.2em' }}
                >
                  {isLoading ? (
                    <span className='text-secondary'>Loading info...</span>
                  ) : (
                    deck.title
                  )}
                </h1>
                <Subheader description={deck.description} />
                {deck.tags &&
                  deck.tags.map((tag) => (
                    <Label key={tag._id} className='mb-2'>
                      {tag.value}
                    </Label>
                  ))}
              </div>
              <p
                className='text-secondary text-uppercase m-0 mt-4 mb-2 font-weight-medium'
                style={{ fontSize: '11px' }}
              >
                {pluralize('card', cards.count, true)} in deck
              </p>
            </div>
            <div className='deck-tabs col-lg-10 offset-lg-1 my-3'>
              <Tab
                className='w-100'
                panes={[
                  {
                    menuItem: OverviewTab.MenuItem(),
                    render: () => <OverviewTab deck={deck} />,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PublicDeckHome.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withErrors(PublicDeckHome);
