import React, { Component } from "react";
import { Helmet } from "react-helmet";

class StripeSuccess extends Component {
  render() {
    return (
      <>
        <Helmet>
          <script type='text/javascript'>
            {`
              ! function(e, n, t, i, o, c, s, a) {
                e.TrackingSystemObject = "r", (s = n.createElement(t)).async = 1, s.src = "https://cdn.refersion.com/refersion.js", s.onload = function() {
                  r.pubKey = "pub_43218f04c35d0bc3bfa2", r.settings.fp_off = !1;
                  r.initializeXDLS().then(() => {
                    r.launchDefault()
                  })
                }, (a = n.getElementsByTagName(t)[0]).parentNode.insertBefore(s, a)
              }(window, document, "script");
          `}
          </script>
          <script type='text/javascript' async>
            {`
              window.addEventListener('load', function() {
                const urlParams = new URLSearchParams(window.location.search);
                const custID = urlParams.get('c_id');
                const rfsn = { 
                  cart: custID,
                  id: localStorage.getItem("rfsn_v4_id"),
                  url: window.location.href,
                  aid: localStorage.getItem("rfsn_v4_aid"),
                  cs: localStorage.getItem("rfsn_v4_cs")
                };

                //r.sendCheckoutEvent(rfsn.cart, rfsn.id, rfsn.url, rfsn.aid, rfsn.cs);
              });
          `}
          </script>
        </Helmet>
        <div className='stripe-success-page'>
          <div className='container mt-5'>
            <div className='row'>
              <div className='col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3'>
                <h1 className='h4 mb-3 text-center'>Sign-Up</h1>
                <p>
                  Thank you for signing-up for the monthly Munkle.it
                  subscription. To finish setting up the account, please check
                  your email for instructions on how to complete your account
                  setup.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default StripeSuccess;
